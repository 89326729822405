import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { geolocated } from "react-geolocated";
import PlacesAutocomplete from "react-places-autocomplete";
import "./AddressAutocomplete.scss";
import waitForGoogleApi from "app/utils/waitForGoogleApi";
import Input from "app/pages/.shared/form/Input";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import { connect } from "react-redux";

class AddressAutocomplete extends React.PureComponent {
	constructor() {
		super();
		this.onChange = this.onChange.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onError = this.onError.bind(this);
		this.serializeAddressComponents = this.serializeAddressComponents.bind(this);
	}

	serializeAddressComponents(place) {
		const components = place.address_components;
		let data = {
			components: {},
			formattedAddress: "",
			name: "",
		};

		const streetNumber = components.find(component => component.types[0] === "street_number");
		const route = components.find(component => component.types[0] === "route");
		const city = components.find(
			component => component.types[0] === "locality" || component.types[0] === "postal_town"
		);
		const country = components.find(component => component.types[0] === "country");
		const postalCode = components.find(
			component => component.types[0] && component.types[0].includes("postal_code")
		);

		data.components.streetNumber = streetNumber && streetNumber.long_name;
		data.components.route = route && route.long_name;
		data.components.city = city && city.long_name;
		data.components.country = country && country.long_name;
		data.components.postalCode = postalCode && postalCode.long_name;
		data.formattedAddress = place.formatted_address;

		if (this.props.enableSuggestionWithPostCode) {
			if (data.components.route) {
				data.name = data.components.route;

				if (data.components.streetNumber) {
					data.name = `${data.components.streetNumber} ${data.name}`;
				}
			}
		} else {
			data.name = place.name;
		}

		return data;
	}

	onChange(address) {
		this.props.field.onChange(address);
	}

	onSelect(address, placeId) {
		// on valorise le champ avec le numero de rue et le nom de la rue avant l'arrivée du détail de l'adresse
		const rue = address.split(",")[0];
		this.props.field.onChange(rue);

		if (typeof this.props.onBeforePlaceSelect === "function") {
			this.props.onBeforePlaceSelect();
		}
		// eslint-disable-next-line no-undef
		const service = new google.maps.places.PlacesService(document.createElement("div"));
		service.getDetails({ placeId }, (place, status) => {
			// eslint-disable-next-line no-undef
			if (status === google.maps.places.PlacesServiceStatus.OK) {
				const placeData = this.serializeAddressComponents(place);
				if (typeof this.props.onPlaceSelect === "function") {
					this.props.onPlaceSelect(placeData);
				} else {
					this.props.field.onChange(placeData.formattedAddress);
				}
			} else if (typeof this.props.onError === "function") {
				this.props.onError("getDetails", status);
			}
		});
	}

	onError(status) {
		if (typeof this.props.onError === "function") {
			this.props.onError("getPredictions", status);
		}
	}

	render() {
		const {
			id,
			field = {},
			isGoogleApiReady,
			label,
			name,
			isGeolocationAvailable,
			isGeolocationEnabled,
			coords = {},
			isRequired = false,
			onBlur,
			enableSuggestionWithPostCode,
			shop = "",
			...restProps
		} = this.props;

		// pour GB, [] pour permettre les suggestions basés sur un code postale
		let options = {
			types: enableSuggestionWithPostCode ? [] : ["address"],
		};

		const country = shop.slice(3, 5);

		if (country) {
			options.componentRestrictions = {
				country,
			};
		}

		if (
			isGoogleApiReady &&
			isGeolocationAvailable &&
			isGeolocationEnabled &&
			coords &&
			coords.latitude &&
			coords.longitude
		) {
			// eslint-disable-next-line no-undef
			options.location = new google.maps.LatLng(coords.latitude, coords.longitude);
			options.radius = 1500000;
			options.strictbounds = true;
		}

		const inputClassName = classNames({
			"address-autocomplete control-group": true,
			"control-group--touched": field.active || field.value,
			"control-group--error": field.touched && field.error,
			"control-group--disabled": !isGoogleApiReady,
		});

		const shouldFetchSuggestions = enableSuggestionWithPostCode
			? field.value.length > 3
			: field.value.length > 6;

		return (
			<div className={inputClassName}>
				<label htmlFor={id} className="control-group__label">
					{label}
				</label>
				{isGoogleApiReady ? (
					<PlacesAutocomplete
						value={field.value}
						onChange={this.onChange}
						onSelect={this.onSelect}
						searchOptions={options}
						shouldFetchSuggestions={shouldFetchSuggestions}
						highlightFirstSuggestion={true}
					>
						{({ getInputProps, suggestions = [], getSuggestionItemProps, loading }) => {
							const reactAutoCompleteInputProps = getInputProps({
								id: `address-autocomplete-${id}`,
								onBlur: onBlur,
							});
							return (
								<div style={{ position: "relative" }}>
									<Input
										isRequired={isRequired}
										{...reactAutoCompleteInputProps}
										field={{
											...field,
											onChange: reactAutoCompleteInputProps.onChange,
											value: reactAutoCompleteInputProps.value,
										}}
										label={label}
										name={name}
										data-cy={restProps["data-cy"]}
									/>

									{loading && (
										<div className="address-autocomplete__loader">
											<HorizontalDotLoader dotSize="3px" color="#3493dc" />
										</div>
									)}

									{suggestions.length > 0 && (
										<div className="address-autocomplete__container">
											{suggestions.map(suggestion => {
												return (
													<div
														key={suggestion.description}
														{...getSuggestionItemProps(suggestion)}
														className={classNames(
															{
																"address-autocomplete__item--active":
																	suggestion.active,
															},
															"address-autocomplete__item"
														)}
													>
														<span>{suggestion.description}</span>
													</div>
												);
											})}
											<div className="address-autocomplete__dropdown-footer">
												<img
													src="/static/icons/powered_by_google.png"
													alt="powered by google"
												/>
											</div>
										</div>
									)}
								</div>
							);
						}}
					</PlacesAutocomplete>
				) : (
					<div style={{ position: "relative" }}>
						<Input
							isRequired={isRequired}
							type="text"
							disabled={true}
							id={id}
							label={label}
							field={field}
							maxLength="50"
						/>
						<div className="address-autocomplete__loader">
							<HorizontalDotLoader dotSize="3px" color="#3493dc" />
						</div>
					</div>
				)}
			</div>
		);
	}
}

AddressAutocomplete.propTypes = {
	id: PropTypes.string,
	shop: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	field: PropTypes.object,
	isGoogleApiReady: PropTypes.bool,
	onPlaceSelect: PropTypes.func,
	onBeforePlaceSelect: PropTypes.func,
	onBlur: PropTypes.func,
	onError: PropTypes.func,
	name: PropTypes.string,
	isGeolocationAvailable: PropTypes.bool,
	isGeolocationEnabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	enableSuggestionWithPostCode: PropTypes.bool,
	coords: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		shop: state.shop,
	};
};

// Voir https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
export default connect(mapStateToProps)(
	geolocated({
		positionOptions: {
			enableHighAccuracy: true,
			maximumAge: 3600000,
			timeout: 30000,
		},
	})(waitForGoogleApi(AddressAutocomplete))
);
