import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { reduxFormDomOnlyProps } from "app/utils/form/formUtils";
import IconTickboxTick from "app/pages/.shared/static/icons/IconTickboxTick";
import IconTickbox from "app/pages/.shared/static/icons/IconTickbox";
import "./checkbox.scss";

class Checkbox extends React.Component {
	constructor() {
		super();
		this.onChange = this.onChange.bind(this);
	}

	onChange(event) {
		const value = event.target.checked;

		if (this.props.field) {
			this.props.field.onChange(value);
		}

		if (typeof this.props.toggle === "function") {
			this.props.toggle(event);
		}
	}

	render() {
		const {
			children,
			name,
			id,
			field = {},
			showIcon = false,
			checked,
			disabled,
			isRequired = false,
			...props
		} = this.props;

		const checkboxClassName = classNames({
			checkbox: true,
			"checkbox--required": isRequired,
			"checkbox--error": field.touched && field.error,
			"checkbox--checked": field.checked || checked,
			"checkbox--disabled": disabled,
		});

		const iconNode = showIcon && (
			<div className="checkbox__icon">
				{field.checked || checked ? (
					<IconTickboxTick height={20} width={20} />
				) : (
					<IconTickbox height={20} width={20} />
				)}
			</div>
		);

		return (
			<div className={checkboxClassName}>
				<input
					type="checkbox"
					{...reduxFormDomOnlyProps(field)}
					className="checkbox__input"
					id={id}
					checked={checked}
					name={name}
					onChange={this.onChange}
					data-cy={props["data-cy"]}
				/>
				<label className="checkbox__label" htmlFor={id}>
					<div className="checkbox__header">
						{iconNode}
						<div className="checkbox__text">{children}</div>
					</div>
				</label>
			</div>
		);
	}
}

Checkbox.propTypes = {
	field: PropTypes.object,
	showIcon: PropTypes.bool,
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	name: PropTypes.string,
	id: PropTypes.string.isRequired,
	toggle: PropTypes.func,
};

export default Checkbox;
