import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { alertTypeProptypes } from "app/utils/propTypes";
import AlertMessage from "app/pages/.shared/AlertMessage";
import { scrollToElement } from "app/utils/scroller";

const GlobalErrorMessagesFormik = ({ isSubmitting, errors, message, alertType, disableScroll }) => {
	const ref = useRef();

	useEffect(() => {
		if (!isSubmitting && !disableScroll) {
			scrollToElement(ref.current, -100);
		}
	}, [isSubmitting]);

	return (
		<div ref={ref}>
			<AlertMessage errors={errors} message={message} alertType={alertType} />
		</div>
	);
};

GlobalErrorMessagesFormik.defaultProps = {
	errors: [],
};

GlobalErrorMessagesFormik.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	errors: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.element])),
	alertType: alertTypeProptypes,
	isSubmitting: PropTypes.bool,
	disableScroll: PropTypes.bool,
};

export default React.memo(GlobalErrorMessagesFormik);
