import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { geolocated } from "react-geolocated";
import PlacesAutocomplete from "react-places-autocomplete";
import "./AddressAutocomplete.scss";
import waitForGoogleApi from "app/utils/waitForGoogleApi";
import HorizontalDotLoader from "app/pages/.shared/HorizontalDotLoader";
import { connect } from "react-redux";
import InputFormik from "app/pages/.shared/form/InputFormik";
import { Field } from "formik";

class AddressAutocompleteFormik extends React.PureComponent {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onSelect = this.onSelect.bind(this);
	}

	serializeAddressComponents(place) {
		const components = place.address_components;
		let data = {
			components: {},
			formattedAddress: "",
			name: "",
		};

		const streetNumber = components.find(component => component.types[0] === "street_number");
		const route = components.find(component => component.types[0] === "route");
		const city = components.find(
			component => component.types[0] === "locality" || component.types[0] === "postal_town"
		);
		const country = components.find(component => component.types[0] === "country");
		const postalCode = components.find(
			component => component.types[0] && component.types[0].includes("postal_code")
		);

		data.components.streetNumber = streetNumber && streetNumber.long_name;
		data.components.route = route && route.long_name;
		data.components.city = city && city.long_name;
		data.components.country = country && country.long_name;
		data.components.postalCode = postalCode && postalCode.long_name;
		data.formattedAddress = place.formatted_address;

		if (this.props.enableSuggestionWithPostCode) {
			if (data.components.route) {
				data.name = data.components.route;

				if (data.components.streetNumber) {
					data.name = `${data.components.streetNumber} ${data.name}`;
				}
			}
		} else {
			data.name = place.name;
		}

		return data;
	}

	onChange(address) {
		const { setFieldValue, enableSuggestionWithPostCode } = this.props;

		if (enableSuggestionWithPostCode) {
			setFieldValue("postal-code", address);
		} else {
			setFieldValue("address-line1", address);
			setFieldValue("address-line2", "");
		}
	}

	onSelect(address, placeId) {
		const { onBeforePlaceSelect, onPlaceSelect, onError } = this.props;

		// on valorise le champ avec le numero de rue et le nom de la rue avant l'arrivée du détail de l'adresse
		const rue = address.split(",")[0];
		this.onChange(rue);

		if (typeof onBeforePlaceSelect === "function") {
			onBeforePlaceSelect();
		}
		// eslint-disable-next-line no-undef
		const service = new google.maps.places.PlacesService(document.createElement("div"));
		service.getDetails({ placeId }, (place, status) => {
			// eslint-disable-next-line no-undef
			if (status === google.maps.places.PlacesServiceStatus.OK) {
				const placeData = this.serializeAddressComponents(place);
				if (typeof onPlaceSelect === "function") {
					onPlaceSelect(placeData);
				} else {
					this.onChange(placeData.formattedAddress);
				}
			} else if (typeof onError === "function") {
				onError("getDetails", status);
			}
		});
	}

	getInputClassname(field, meta, isGoogleApiReady) {
		return classNames("address-autocomplete control-group", {
			"control-group--touched": meta.touched || field.value,
			"control-group--error": meta.touched && meta.error,
			"control-group--disabled": !isGoogleApiReady,
		});
	}

	render() {
		const {
			id,
			isGoogleApiReady,
			label,
			name,
			isGeolocationAvailable,
			isGeolocationEnabled,
			coords,
			isRequired,
			enableSuggestionWithPostCode,
			shop,
			...restProps
		} = this.props;

		// pour GB, [] pour permettre les suggestions basés sur un code postale
		let options = {
			types: enableSuggestionWithPostCode ? [] : ["address"],
		};

		const [, country] = shop.split("-");

		if (country) {
			options.componentRestrictions = {
				country,
			};
		}

		if (
			isGoogleApiReady &&
			isGeolocationAvailable &&
			isGeolocationEnabled &&
			coords &&
			coords.latitude &&
			coords.longitude
		) {
			// eslint-disable-next-line no-undef
			options.location = new google.maps.LatLng(coords.latitude, coords.longitude);
			options.radius = 1500000;
			options.strictbounds = true;
		}

		// should use `useField()` instead of <Field /> when migrated to functionalComponent
		return (
			<Field name={name}>
				{({ field, meta }) => {
					const shouldFetchSuggestions = enableSuggestionWithPostCode
						? field.value && field.value.length > 3
						: field.value && field.value.length > 6;

					return (
						<div className={this.getInputClassname(field, meta, isGoogleApiReady)}>
							{isGoogleApiReady ? (
								<PlacesAutocomplete
									value={field.value}
									onChange={this.onChange}
									onSelect={this.onSelect}
									searchOptions={options}
									shouldFetchSuggestions={shouldFetchSuggestions}
									highlightFirstSuggestion={true}
								>
									{({
										getInputProps,
										suggestions = [],
										getSuggestionItemProps,
										loading,
									}) => {
										return (
											<div style={{ position: "relative" }}>
												<InputFormik
													id={id}
													name={name}
													isRequired={isRequired}
													label={label}
													{...getInputProps()}
													autoComplete="disabled"
													data-cy={restProps["data-cy"]}
												/>

												{loading && (
													<div className="address-autocomplete__loader">
														<HorizontalDotLoader
															dotSize="3px"
															color="#3493dc"
														/>
													</div>
												)}

												{suggestions.length > 0 && (
													<div className="address-autocomplete__container">
														{suggestions.map(suggestion => {
															return (
																<div
																	key={suggestion.description}
																	{...getSuggestionItemProps(
																		suggestion
																	)}
																	className={classNames(
																		{
																			"address-autocomplete__item--active":
																				suggestion.active,
																		},
																		"address-autocomplete__item"
																	)}
																>
																	<span>
																		{suggestion.description}
																	</span>
																</div>
															);
														})}
														<div className="address-autocomplete__dropdown-footer">
															<img
																src="/static/icons/powered_by_google.png"
																alt="powered by google"
															/>
														</div>
													</div>
												)}
											</div>
										);
									}}
								</PlacesAutocomplete>
							) : (
								<div style={{ position: "relative" }}>
									<InputFormik
										id={id}
										name={name}
										isRequired={isRequired}
										disabled
										label={label}
										maxLength="50"
									/>
									<div className="address-autocomplete__loader">
										<HorizontalDotLoader dotSize="3px" color="#3493dc" />
									</div>
								</div>
							)}
						</div>
					);
				}}
			</Field>
		);
	}
}

AddressAutocompleteFormik.defaultProps = {
	shop: "",
	isRequired: false,
	coords: {},
};

AddressAutocompleteFormik.propTypes = {
	id: PropTypes.string,
	shop: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	isGoogleApiReady: PropTypes.bool,
	onPlaceSelect: PropTypes.func,
	onBeforePlaceSelect: PropTypes.func,
	onError: PropTypes.func,
	name: PropTypes.string,
	isGeolocationAvailable: PropTypes.bool,
	isGeolocationEnabled: PropTypes.bool,
	isRequired: PropTypes.bool,
	enableSuggestionWithPostCode: PropTypes.bool,
	coords: PropTypes.object,
	setFieldValue: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
	return {
		shop: state.shop,
	};
};

// Voir https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
export default connect(mapStateToProps)(
	geolocated({
		positionOptions: {
			enableHighAccuracy: true,
			maximumAge: 3600000,
			timeout: 30000,
		},
	})(waitForGoogleApi(AddressAutocompleteFormik))
);
