import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { messagePropType } from "app/utils/propTypes";
import { useField } from "formik";
import "./AdvancedSelectFormik.scss";
import classNames from "classnames";

const AdvancedSelectFormik = props => {
	const { id, label, className, isRequired, icon, ...restProps } = props;
	const [field, meta, helpers] = useField(props);
	const { setValue, setTouched, setError } = helpers;

	const inputClassName = classNames("advanced-select", className, {
		"advanced-select--required": isRequired,
		"advanced-select--touched": (meta.touched && !meta.error) || field.value,
		"advanced-select--error": meta.touched && meta.error,
		"advanced-select--with-icon": icon,
	});

	const handleFocus = useCallback(() => {
		setTouched(true);
		setError();
	}, []);

	const setUntouchedOnBlur = useCallback(event => {
		if (event.target.value === "") {
			setTouched(false);
		}
	}, []);

	const handleChange = useCallback(option => {
		setValue(option);
	}, []);

	return (
		<div className={inputClassName} data-cy={restProps["data-cy"]}>
			<Select
				id={id}
				isClearable={true}
				backspaceRemovesValue={true}
				className="advanced-select__select"
				classNamePrefix="advanced-select__select"
				styles={{
					dropdownIndicator: (base, state) => ({
						...base,
						transition: "all .2s ease",
						transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
					}),
					menuList: base => ({ ...base, maxHeight: "175px", padding: "0" }), // on limite volontairement pour afficher la moitié du 4ème pour suggérer la possibilité de scroller pour afficher la suite
					option: base => ({
						...base,
						border: "none",
						height: "50px",
						display: "flex",
						alignItems: "center",
					}),
				}}
				placeholder={null}
				blurInputOnSelect={true}
				captureMenuScroll={true}
				menuShouldScrollIntoView={true}
				{...field}
				onFocus={handleFocus}
				onBlur={setUntouchedOnBlur}
				onChange={handleChange}
				{...restProps}
			/>
			{icon && <div className="advanced-select__icon">{icon}</div>}
			<label htmlFor={id} className="advanced-select__label">
				{label}
			</label>
		</div>
	);
};

AdvancedSelectFormik.propTypes = {
	id: PropTypes.string,
	label: messagePropType,
	value: PropTypes.any,
	isSearchable: PropTypes.bool,
	isClearable: PropTypes.bool,
	getOptionLabel: PropTypes.func,
	getOptionValue: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.array,
	onMenuOpen: PropTypes.func,
	onMenuClose: PropTypes.func,
	isRequired: PropTypes.bool,
	icon: PropTypes.element,
};

export default React.memo(AdvancedSelectFormik);
